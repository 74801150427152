<template>
  <section id="network">
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- header -->
        <div class="vx-row header">
          <div class="vx-col w-full item-holder">
            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard
                :item="network[0]"
                color="primary"
                :parent="network[0].referrer_id"
              />
            </div>
          </div>
        </div>

        <!-- step 2 -->
        <div class="vx-row child">
          <div class="vx-col w-full item-holder middle-network-margin">
            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard :item="network[1]" color="success" />
            </div>

            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard :item="network[2]" color="success" />
            </div>
          </div>
        </div>

        <!-- step 3 -->
        <div class="vx-row child hidden sm:block">
          <div class="vx-col w-full item-holder">
            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[3]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[4]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[5]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[6]" color="danger" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import NetworkCard from "@/components/network/NetworkCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { NetworkCard },
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters({
      network: "moduleNetwork/network"
    })
  },
  methods: {
    ...mapActions({
      getNetworkDetail: "moduleNetwork/getNetworkDetail"
    }),
    async get_Network_Detail() {
      // Start Loading
      this.$vs.loading();
      //
      await this.getNetworkDetail(this.$route.params.id)
      // Close Loading
      this.$vs.loading.close();
      //
    }
  },

  async mounted() {
    this.get_Network_Detail();
  }
};
</script>

<style lang="scss">
@import "./network.scss";
</style>
